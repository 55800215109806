<!--  -->
<template>
  <div class="news-manage">
    <el-button class="distribute-news" type="primary" @click="handleAddNews()"
      >发布新闻</el-button
    >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" name="first">
       <el-table ref="filterTable" :data="tableData" style="width: 100%">
        <el-table-column prop="title" label="新闻标题"> </el-table-column>
        <el-table-column prop="digest" label="摘要"> </el-table-column>
        <el-table-column prop="update_time" label="最后修改时间" sortable>
        </el-table-column>
        <el-table-column prop="sort" label="人工排序" sortable> </el-table-column>
        <el-table-column prop="status" label="状态"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEditNews(scope.$index)">编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      </el-tab-pane>
      <el-tab-pane v-for="newsitem in newstype" :key="newsitem.id" :label="newsitem.title">
      <el-table ref="filterTable" :data="tableData" style="width: 100%">
        <el-table-column prop="title" label="新闻标题"> </el-table-column>
        <el-table-column prop="digest" label="摘要"> </el-table-column>
        <el-table-column prop="update_time" label="最后修改时间" sortable>
        </el-table-column>
        <el-table-column prop="sort" label="人工排序" sortable> </el-table-column>
        <el-table-column prop="status" label="状态"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEditNews(scope.$index)">编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [],
      newstype: [],
      activeName: 'first',
      nowlangtype: '' // 判断当前语言类型
    }
  },
  watch: {
    $route (to, from) {
      if (this.$route.query.lang === 'PT') {
        this.nowlangtype = 'pt-PT'
      } else if (this.$route.query.lang === 'EN') {
        this.nowlangtype = 'en-US'
      } else {
        this.nowlangtype = 'zh-CN'
      }
      this.getNewstype()
      this.getListnews('')
    }
  },
  methods: {
    handleClick (e) {
      this.newstype.forEach((element) => {
        if (element.title === e.label) {
          this.getListnews(element.id)
        } else if (e.label === '全部') {
          this.getListnews()
        }
      })
    },
    handleDelete (index) {
      this.$confirm('确认删除文章?', '提示', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post('api/news/delnewsbyid', { id: this.tableData[index].id }).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.tableData.splice(index, 1)
        }).catch(err => console.log(err))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleAddNews () {
      this.$router.push({ path: 'newsdetail', query: { lang: this.$route.query.lang } })
    },
    handleEditNews (index) {
      const detail = this.tableData[index].id
      this.$router.push({ path: 'newsdetail', query: { id: detail, lang: this.$route.query.lang } })
      console.log(detail)
      /* this.$axios.post('api/news/backdetail', { news_no: detail }).then(res => {
        console.log(res.data.data)
        this.$router.push({ path: 'newsdetail', query: { item: JSON.stringify(res.data.data) } })
      }) */
    },
    // 获取新闻类型
    getNewstype () {
      this.$axios.post('api/news/getnewstypelist', { lang: this.nowlangtype }).then(res => {
        console.log('类型', res)
        this.newstype = res.data.data
      })
    },
    // 获取新闻列表的公共方法
    getListnews (e) {
      this.tableData = []
      let param = {}// eslint-disable-line no-unused-vars
      if (e && e !== '0') {
        param = { type: e, lang: this.nowlangtype }
      } else {
        param = { lang: this.nowlangtype }
      }
      this.$axios.post('api/news/getnewslist', param).then(res => {
        res.data.data.data.map(item => {
          if (Number(item.status) === 1) item.status = '公开'
          else item.status = '不公开'
        })
        this.tableData = res.data.data.data
      })
    }
  },
  created () {},
  mounted () {
    if (this.$route.query.lang === 'PT') {
      this.nowlangtype = 'pt-PT'
    } else if (this.$route.query.lang === 'EN') {
      this.nowlangtype = 'en-US'
    } else {
      this.nowlangtype = 'zh-CN'
    }
    this.getNewstype()
    this.getListnews('')
  }
}
</script>
<style scoped lang='less'>
.news-manage {
  padding: 30px;
  .distribute-news {
    margin-bottom: 10px;
  }
}
.el-tabs__header{
  margin-bottom: 15px;
}
</style>
